import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const InstallButtonStyled = styled(Button)({
  backgroundColor: "#20D561",
  padding: "16px 40px 16px 40px",
  borderRadius: "32px",
  fontFamily: "Chirp",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "19.09px",
  color: "#212121",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#20D561",
    opacity: "0.8",
  },
});

const InstallButton: React.FC = () => {
  const handleInstallButton = () => {
    var isIOS = getMobileOperatingSystem().toLowerCase() === 'ios';
    if (isIOS) {
      window.open("https://apps.apple.com/vn/app/trinity/id6657977300", "_blank");

    } else {
      window.open("https://play.google.com/store/apps/details?id=en.app.trinity", "_blank");

    }
  };

  return (
    <InstallButtonStyled onClick={handleInstallButton}>
      Install now
    </InstallButtonStyled>
  );
};

function getMobileOperatingSystem() {
  var platform = navigator.platform;

  var isIOS = /iPad|iPhone|iPod|Mac/.test(platform);

  if (isIOS) {
      return 'iOS';
  }

  return 'Android';

}

export default InstallButton;
