import styled from "@emotion/styled";
import { Container } from "@mui/material";
import React from "react";
import Header from "./header/Header";
import Content from "./body/Content";
import Footer from "./footer/Footer";

export const LandingPageStyled = styled(`div`)({
  margin: 0,
  padding: "0px 0px 0px 0px",
  background:
    "linear-gradient(180deg, #052711 0%, #111713 24.5%, #141414 48.5%)",
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  color: "white",
});

const LadingPage: React.FC = () => {
  return (
    <LandingPageStyled>
      <Container maxWidth="lg">
        <Header />
        <Content />
      </Container>
    </LandingPageStyled>
  );
};

export default LadingPage;
