import { Container, Stack, Typography } from "@mui/material";
import { LandingPageStyled } from "../App";
import { useNavigate } from "react-router-dom";

const LinkPrivacy = () => {
  const navigator = useNavigate();
  const link = () => {
    navigator("/privacy");
  };
  return (
    <span
      style={{
        backgroundColor: "orange",
        color: "black",
        cursor: "pointer",
      }}
      onClick={link}
    >
      Privacy Policy
    </span>
  );
};

const Term: React.FC = () => {
  return (
    <LandingPageStyled>
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: "40px",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingBottom: "40px",
        }}
      >
        <Stack spacing={1}>
          <Typography
            sx={{
              fontSize: 32,
              fontFamily: "Chirp",
              textAlign: "center",
            }}
          >
            TERMS OF SERVICE
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: 12,
                md: 16,
              },
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            1. INTRODUCTION
          </Typography>
          <span>
            By accessing or using Trinity's Services, you agree to be bound by
            this Terms of Use Agreement (the “Terms” or “Agreement”), including
            our<> </>
            <LinkPrivacy />, so it is important that you read this Agreement and
            these policies and procedures carefully before you create an
            account.
          </span>
          <span>
            PLEASE CAREFULLY REVIEW THE DISPUTE RESOLUTION PROVISIONS IN SECTION
            15 BELOW. THESE GOVERN THE MANNER IN WHICH DISPUTES WILL BE
            ADDRESSED BETWEEN YOU AND Trinity. THESE PROVISIONS INCLUDE A
            MANDATORY PRE-ARBITRATION INFORMAL DISPUTE RESOLUTION PROCESS
            (EXCEPT FOR INDIVIDUAL CLAIMS OF SEXUAL ASSAULT OR SEXUAL HARASSMENT
            OCCURRING IN CONNECTION WITH YOUR USE OF THE SERVICES), AN
            ARBITRATION AGREEMENT, SMALL CLAIMS COURT ELECTION, CLASS ACTION
            WAIVER, ADDITIONAL PROCEDURES FOR MASS ARBITRATION FILINGS, AND JURY
            TRIAL WAIVER THAT AFFECT YOUR RIGHTS. IN ARBITRATION, THERE IS
            TYPICALLY LESS DISCOVERY AND APPELLATE REVIEW THAN IN COURT.
          </span>
          <span>
            We may update these Terms from time to time, so check this page
            regularly for updates. The terms “us”, “we”, “Company” and/or
            “Trinity” Together you and Trinity may be referred to as the
            “Parties” or separately as “Party”. By accessing or using our
            Services on the Trinity mobile application (the "App"), or any other
            platforms or services Trinity may offer (collectively, the "Service"
            or our "Services"), you agree to, and are bound by this Agreement.
            This Agreement applies to anyone who accesses or uses our Services,
            regardless of registration or subscription status. Your access and
            use of our Services is also subject to the<> </>
            <LinkPrivacy />
            <span>
              , and any terms disclosed and agreed to by you when you purchase
              additional features, products, or services from Trinity
              ("Additional Terms Upon Purchase"), which are incorporated into
              this Agreement by reference. If you do not wish to be bound by
              this Agreement, do not access or use our Services. Subject to
              applicable law, we reserve the right to modify, amend, or change
              the Terms at any time. Notice of material changes will be posted
              on this page with an updated effective date. In certain
              circumstances, we may notify you of a change to the Terms via
              email or other means; however, you are responsible for regularly
              checking this page for any changes. Your continued access or use
              of our Services constitutes your ongoing consent to any changes,
              and as a result, you will be legally bound by the updated Terms.
              If you do not accept a change to the Terms, you must stop
              accessing or using our Services immediately. Notwithstanding the
              foregoing, any material changes to the Limitation of Liability in
              Section 14 and the Dispute Resolution provisions in Section 15
              below will require your affirmative acceptance. Further, we
              reserve the right to change the availability of features in our
              subscription plans.
            </span>
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            2. ACCOUNT ELIGIBILITY; YOUR RESPONSIBILITIES
          </Typography>
          <span>
            Before you create an account on Trinity, make sure you are eligible
            to use our Services. This Section also details what you can and
            can't do when using the Services, as well as the rights you grant
            Trinity.
          </span>
          <span>
            You are not authorized to create an account or use the Services
            unless all of the following are true, and by using our Services, you
            represent and warrant that:
          </span>
          <span>
            1. You are an individual (i.e., not any body corporate, partnership
            or other business entity) at least 13 years old;
          </span>
          <span>
            2. You are legally qualified to enter a binding contract with
            Trinity;
          </span>
          <span>
            3. You are not located in a country that is subject to a U.S.
            Government embargo, or that has been designated by the U.S.
            Government as a "terrorist supporting" country;
          </span>
          <span>
            4. You are not on any list of individuals prohibited from conducting
            business with the United States (for example, the U.S. Treasury
            Department’s list of Specially Designated Nationals or any similar
            government agency list) nor do you face any other similar
            prohibition;
          </span>
          <span>5. You are not prohibited by law from using our Services;</span>
          <span>
            6. You have not committed, been convicted of, or pled no contest to
            a felony or indictable offense (or crime of similar severity), a sex
            crime, or any crime involving violence or a threat of violence,
            unless you have received clemency for a non-violent crime and we
            have determined that you are not likely to pose a threat to other
            users of our Services;
          </span>
          <span>
            7. You are not required to register as a sex offender with any
            state, federal or local sex offender registry;
          </span>
          <span>8. You do not have more than one account on our Services;</span>
          <span>
            9. You have not previously been removed from our Services or our
            affiliates’ services by us or our affiliates, unless you have our
            express written permission to create a new account.
          </span>
          <span>
            10. If at any time you cease to meet these requirements, all
            authorization to access our Services or systems is automatically
            revoked, and you must immediately delete your account, and we retain
            the right to remove your access to our Services without warning.
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            You agree to:
          </Typography>
          <span>
            1. Comply with these Terms, and check this page from time to time to
            ensure you are aware of any changes;
          </span>
          <span>
            2. Comply with all applicable laws, including without limitation,
            privacy laws, intellectual property laws, anti-spam laws, and
            regulatory requirements;
          </span>
          <span>3. Use the latest version of the Website and/or App;</span>
          <span>
            4. Take reasonable measures to protect the security of your login
            information.
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            You agree that you will not:
          </Typography>
          <span>
            1. Misrepresent your identity, age, or affiliations with a person or
            entity;
          </span>
          <span>
            2. Use the Services in a way that damages the Services or prevents
            their use by other users;
          </span>
          <span>
            3. Use the Services in a way that damages the Services or prevents
            their use by other users;
          </span>
          <span>
            4. Use our Services for any harmful, illegal, or nefarious purpose,
            including, but not limited to, using any Virtual Items for purposes
            of money laundering or other financial crimes;
          </span>
          <span>
            5. Harass, bully, stalk, intimidate, assault, defame, harm or
            otherwise abuse or cause psychological harm;
          </span>
          <span>6. Post or share Prohibited Content (see below);</span>
          <span>
            7. Solicit passwords for any purpose, or personal identifying
            information for commercial or unlawful purposes from other users or
            disseminate another person's personal information without his or her
            permission;
          </span>
          <span>
            8. Solicit money or other items of value from another user, whether
            as a gift, loan, or form of compensation;
          </span>
          <span>9. Use another user's account;</span>
          <span>
            10. Use our Services in relation to fraud, a pyramid scheme, or
            other similar practice;
          </span>
          <span>
            11. Use our Services in relation to any political campaign financing
            or for the purpose of influencing any election, other than sharing
            your own personal political opinions.
          </span>
          <span>
            12. Violate the terms of the license granted to you by Trinity (see
            Section 6 below).
          </span>
          <span>
            13. Disclose private or proprietary information that you do not have
            the right to disclose;
          </span>
          <span>
            14. Copy, modify, transmit, distribute, or create any derivative
            works from, any Member Content or Our Content, or any copyrighted
            material, images, trademarks, trade names, service marks, or other
            intellectual property, content or proprietary information accessible
            through our Services without Trinity's prior written consent;
          </span>
          <span>
            15. Express or imply that any statements you make are endorsed by
            Trinity;
          </span>
          <span>
            16. Use any robot, crawler, site search/retrieval application, proxy
            or other manual or automatic device, method or process to access,
            retrieve, index, "data mine," or in any way reproduce or circumvent
            the navigational structure or presentation of our Services or its
            contents;
          </span>
          <span>
            17. Upload viruses or other malicious code or otherwise compromise
            the security of our Services;
          </span>
          <span>
            18. Forge headers or otherwise manipulate identifiers to disguise
            the origin of any information transmitted to or through our
            Services;
          </span>
          <span>
            19. "Frame" or "mirror" any part of our Services without Trinity's
            prior written authorization;
          </span>
          <span>
            20. Use meta tags or code or other devices containing any reference
            to Trinity or the platform (or any trademark, trade name, service
            mark, logo or slogan of Trinity) to direct any person to any other
            website for any purpose;
          </span>
          <span>
            21. Modify, adapt, sublicense, translate, sell, reverse engineer,
            decipher, decompile or otherwise disassemble any portion of our
            Services, or cause others to do so;
          </span>
          <span>
            22. Use or develop any third-party applications that interact with
            our Services or Member Content or information without our written
            consent, including but not limited to artificial intelligence or
            machine learning systems;
          </span>
          <span>
            23. Use, access, or publish the Trinity application programming
            interface without our written consent;
          </span>
          <span>
            24. Probe, scan or test the vulnerability of our Services or any
            system or network;
          </span>
          <span>
            25. Encourage, promote, or agree to engage in any activity that
            violates these Terms;
          </span>
          <span>
            26. Create a new account after we suspend or terminate your account,
            unless you receive our express permission;
          </span>
          <span>
            27. Submit a report about a member’s behavior or content that is
            false, misleading, or otherwise manifestly unfounded, or abuse any
            Trinity reporting or appeals request system made available.
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            The license granted to you under these Terms and any authorization
            to access the Services is automatically revoked in the event that
            you do any of the above. Prohibited Content - Trinity prohibits
            uploading or sharing content that:
          </Typography>
          <span>
            1. Could reasonably be deemed to be offensive or to harass, abuse or
            cause psychological distress to another person;
          </span>
          <span>2. Is obscene, pornographic, violent or contains nudity;</span>
          <span>
            3. Is abusive, threatening, discriminatory or that promotes or
            encourages racism, sexism, hatred or bigotry;
          </span>
          <span>
            4. Encourages or facilitates any illegal activity including, without
            limitation, terrorism, inciting racial hatred or the submission of
            which in itself constitutes committing a criminal offense;
          </span>
          <span>
            5. Encourages or facilitates any activity that may result in harm to
            the user or another person, including, but not limited to, promotion
            of self-harm, eating disorders, dangerous challenges, violent
            extremism
          </span>
          <span>6. Is defamatory, libelous, or untrue;</span>
          <span>
            7. Relates to commercial activities (including, without limitation,
            sales, competitions, promotions, and advertising, solicitation for
            services, sex work, "sugar daddy" or "sugar baby" relationships,
            links to other websites or premium line telephone numbers);
          </span>
          <span>8. Involves or facilitates the transmission of spam;</span>
          <span>
            9. Contains any spyware, adware, viruses, corrupt files, worm
            programs or other malicious code designed to interrupt, damage or
            limit the functionality of or disrupt any software, hardware,
            telecommunications, networks, servers
          </span>
          <span>
            9. Contains any spyware, adware, viruses, corrupt files, worm
            programs or other malicious code designed to interrupt, damage or
            limit the functionality of or disrupt any software, hardware,
            telecommunications, networks, servers
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            Other equipment, Trojan horse or any other material designed to
            damage, interfere with, wrongly intercept or expropriate any data or
            personal information whether from Trinity or otherwise;
          </Typography>
          <span>
            1. Infringes upon any third party's rights (including, without
            limitation, intellectual property rights and privacy rights);
          </span>
          <span>
            2. Was not written by you, unless expressly authorized by Trinity;
          </span>
          <span>
            3. Includes the image or likeness of another person without that
            person's consent (or in the case of a minor, the minor's parent or
            guardian);
          </span>
          <span>
            4. Includes an image or likeness of a minor who is unaccompanied by
            the minor's parent or guardian or not fully clothed or otherwise
            depicts or implies a minor engaged in sexual activity;
          </span>
          <span>5. Is inconsistent with the intended use of the Services;</span>
          <span>
            6. May harm the reputation of Trinity or its affiliates, meaning the
            uploading or sharing of content on the Trinity platform that is
            defamatory to Trinity or its affiliates or advocates misuse of the
            Service or any service provided by Trinity’s affiliates.
          </span>
          <span>
            The uploading or sharing of content that violates these Terms
            ("Prohibited Content") may result in the immediate suspension or
            termination of your account.
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            3. CONTENT
          </Typography>
          <span>
            1. It is important that you understand your rights and
            responsibilities with regard to the content on our Services,
            including any content you provide or post. You are expressly
            prohibited from posting inappropriate content. While using our
            Services, you will have access to: (i) content that you upload or
            provide while using our Services, even if suggested by our Services
            ("Your Content"); (ii) content that other users upload or provide
            while using our Services ("Member Content"); and (iii) content that
            Trinity provides on and through our Services ("Our Content"). In
            this agreement, "content" includes, without limitation, all text,
            images, video, audio, or other material on our Services, including
            information on users' profiles and in direct messages between users.
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            3a. YOUR CONTENT
          </Typography>
          <span>
            You are responsible for Your Content. Don't share anything that you
            wouldn't want others to see, that would violate this Agreement, or
            that may expose you or us to legal liability. You are solely
            responsible and liable for Your Content, and, therefore, you agree
            to indemnify, defend, release, and hold us harmless from any claims
            made in connection with Your Content. You represent and warrant to
            us that the information you provide to us or any other user is
            accurate, including any information submitted through Facebook or
            other third-party sources (if applicable), and that you will update
            your account information as necessary to ensure its accuracy.
          </span>
          <span>
            The content included on your individual profile should be relevant
            to the intended use of our Services. You may not upload any
            Prohibited Content, and your content must further comply with our
            policy. You may not display any personal contact, banking
            information, or peer-to-peer payment information, whether in
            relation to you or any other person (for example, names, home
            addresses or postcodes, telephone numbers, email addresses, URLs,
            credit/debit card, peer-to-peer payment user name, or other banking
            details). If you choose to reveal any personal information about
            yourself to other users, you do so at your own risk. We strongly
            encourage you to use caution in disclosing any personal information
            online. Your individual profile will be visible to other people
            around the world, so be sure that you are comfortable sharing Your
            Content before you post. You acknowledge and agree that Your Content
            may be viewed by other users, and, notwithstanding these Terms,
            other users may share Your Content with third parties. By uploading
            Your Content, you represent and warrant to us that you have all
            necessary rights and licenses to do so and automatically grant us a
            license to use Your Content as provided under Section 7 below. We
            may provide tools and features to enhance individual expression
            through Your Content and Member Content (described in Section 3b),
            and we’re constantly developing new technologies to improve our
            Services. Certain tools or features may allow you to generate or
            enhance content based on Your Content. This is still Your Content,
            and you are responsible for it and its accuracy, as well as your use
            of it on our Services and any and all decisions made, actions taken,
            and failures to take action based on Your Content. Be careful in
            choosing and sharing Your Content.
          </span>
          <span>
            You understand and agree that we may monitor or review Your Content,
            and we have the right to remove, delete, edit, limit, or block or
            prevent access to any of Your Content at any time at our sole
            discretion. Furthermore, you understand and agree that we have no
            obligation to display or review Your Content.
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            3b. MEMBER CONTENT
          </Typography>
          <span>
            While you will have access to Member Content, it is not yours and
            you may not copy or use Member Content for any purpose except as
            contemplated by these Terms.
          </span>
          <span>
            Other users will also share content on our Services. Member Content
            belongs to the user who posted the content and is stored on our
            servers and displayed at the direction of that user. You should
            always carefully review and independently verify Member Content for
            accuracy. Other users may use tools to generate or enhance content
            based on the Member Content they provide. Member Content may include
            biased, incorrect, harmful, offensive, or misleading information.
            Other users are responsible for their Member Content, as well as any
            and all decisions made, actions taken, and failures to take action
            based on their use of Member Content.
          </span>
          <span>
            You do not have any rights in relation to Member Content, and,
            unless expressly authorized by Trinity, you may only use Member
            Content to the extent that your use is consistent with our Services'
            purpose of allowing us to communicate with and meet one another. You
            may not copy the Member Content or use Member Content for commercial
            purposes, to spam, to harass, or to make unlawful threats. We
            reserve the right to terminate your account if you misuse Member
            Content.
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            3c. OUR CONTENT
          </Typography>
          <span>
            Trinity owns or licenses all other content on our Services.
          </span>
          <span>
            Any other text, content, graphics, user interfaces, trademarks,
            logos, sounds, artwork, images, and other intellectual property
            appearing on our Services is owned, controlled or licensed by us and
            protected by copyright, trademark and other intellectual property
            law rights. All rights, title, and interest in and to Our Content
            remains with us at all times.
          </span>
          <span>
            We grant you a limited license to access and use Our Content as
            provided under Section 6 below, and we reserve all other rights.
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            4. INAPPROPRIATE CONTENT AND MISCONDUCT; REPORTING
          </Typography>
          <span>
            Trinity does not tolerate inappropriate content or behavior on our
            Services. We are committed to maintaining a positive and respectful
            community, and we do not tolerate any inappropriate content or
            misconduct, whether on or off of the Services (including, but not
            limited to, on services operated by our affiliates). We encourage
            you to report any inappropriate Member Content or misconduct by
            other users. You can report a user directly through the "Report
            User" link on a user's profile or in the messaging experience. You
            may also contact us at admin@learntrinity.com
          </span>
          <span>
            Member Content is subject to the terms and conditions of Sections
            512(c) and/or 512(d) of the Digital Millennium Copyright Act 1998.
            To submit a complaint regarding Member Content that may constitute
            intellectual property infringement, see Section 12 (Digital
            Millennium Copyright Act) below
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            5. PRIVACY
          </Typography>
          <span>
            Privacy is important to us. We have a separate policy about it that
            you should read.
          </span>
          <span>
            For information about how Trinity and its affiliates collect, use,
            and share your personal data, please read our <LinkPrivacy />
            <> </>By using our Services, you agree that we may use your personal
            data in accordance with our <LinkPrivacy />
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            6. RIGHTS YOU ARE GRANTED BY TRINITY
          </Typography>
          <span>
            Trinity grants you the right to use and enjoy our Services, subject
            to these Terms. For as long as you comply with these Terms, Trinity
            grants you a personal, worldwide, royalty-free, non-assignable,
            non-exclusive, revocable, and non-sublicensable license to access
            and use our Services for purposes as intended by Trinity and
            permitted by these Terms and applicable laws. This license and any
            authorization to access the Service are automatically revoked in the
            event that you fail to comply with these Terms.
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            7. RIGHTS YOU GRANT TRINITY
          </Typography>
          <span>
            You own all of the content you provide to Trinity, but you also
            grant us the right to use Your Content as provided in this
            Agreement.
          </span>
          <span>
            By creating an account, you grant to Trinity a worldwide, perpetual,
            transferable, sub- licensable, royalty-free right and license to
            host, store, use, copy, display, reproduce, adapt, edit, publish,
            translate, modify, reformat, incorporate into other works,
            advertise, distribute and otherwise make available to the general
            public Your Content, including any information you authorize us to
            access from Facebook or other third- party sources (if applicable),
            in whole or in part, and in any way and in any format or medium
            currently known or developed in the future. Trinity's license to
            Your Content shall be non-exclusive, except that Trinity's license
            shall be exclusive with respect to derivative works created through
            use of our Services. For example, Trinity would have an exclusive
            license to screenshots of our Services that include Your Content.
          </span>
          <span>
            In addition, so that Trinity can prevent the use of Your Content
            outside of our Services, you authorize Trinity to act on your behalf
            with respect to infringing uses of Your Content taken from our
            Services by other users or third parties. This expressly includes
            the authority, but not the obligation, to send notices pursuant to
            17 U.S.C. § 512(c)(3) (i.e., DMCA Takedown Notices) on your behalf
            if Your Content is taken and used by third parties outside of our
            Services. Trinity is not obligated to take any action with regard to
            use of Your Content by other users or third parties. Trinity's
            license to Your Content is subject to your rights under applicable
            law (for example, laws regarding personal data protection to the
            extent the content contains personal information as defined by those
            laws).
          </span>
          <span>
            In consideration for Trinity allowing you to use our Services, you
            agree that we, our affiliates, and our third-party partners may
            place advertising on our Services. By submitting suggestions or
            feedback to Trinity regarding our Services, you agree that Trinity
            may use and share such feedback for any purpose without compensating
            you.
          </span>
          <span>
            You agree that Trinity may access, preserve, and disclose your
            account information, including Your Content, if required to do so by
            law or upon a good faith belief that such access, preservation, or
            disclosure is reasonably necessary to: (i) comply with legal
            process; (ii) enforce these Terms; (iii) respond to claims that any
            content violates the rights of third parties; (iv) respond to your
            requests for customer service; (v) protect the rights, property or
            personal safety of the Company or any other person, or (vi) to
            investigate, prevent, or take other action regarding illegal
            activity, suspected fraud or other wrongdoing.
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            8. PURCHASES AND AUTOMATICALLY RENEWING SUBSCRIPTIONS
          </Typography>
          <span>
            You will have the opportunity to purchase products and services from
            Trinity. If you purchase a subscription, it will automatically renew
            - and you will be charged - until you cancel. Trinity may offer
            products and services for purchase through iTunes, Google Play, or
            other external services authorized by Trinity (each, an "External
            Service," and any purchases made thereon, an "External Service
            Purchase"). Trinity may also offer products and services for
            purchase via credit card or other payment processors on the Website
            or inside the App ("Internal Purchases").
          </span>
          <span>
            If you purchase a subscription, it will automatically renew until
            you cancel, in accordance with the terms disclosed to you at the
            time of purchase, as further described below. If you cancel your
            subscription, you will continue to have access to your subscription
            benefits until the end of your subscription period, at which point
            it will expire.
          </span>
          <span>
            Because our Services may be utilized without a subscription,
            canceling your subscription does not remove your profile from our
            Services. If you wish to fully terminate your account, you must
            terminate your account as set forth in Section 9.
          </span>
          <span>
            Trinity operates a global business, and our pricing varies based on
            a number of factors. We frequently offer promotional rates - which
            can vary based on region, length of subscription, bundle size, past
            purchases, account activity and more. We also regularly test new
            features and payment options. If you do not timely cancel your
            subscription, your subscription will be renewed at the full price as
            indicated when the purchase was made, without any additional action
            by you, and you authorize us to charge your payment method for these
            amounts. To the extent permissible by law, we reserve the right,
            including without prior notice, to limit the available quantity of
            or discontinue making available any product, feature, service or
            other offering; to impose conditions on the honoring of any coupon,
            discount, offer or other promotion; to bar any user from making any
            transaction; and to refuse to provide any user with any product,
            service or other offering.
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            8a. EXTERNAL SERVICE PURCHASES AND SUBSCRIPTIONS
          </Typography>
          <span>
            External Service Purchases, including subscriptions, may be
            processed through the External Service, in which case those
            purchases must be managed through your External Service Account.
            Subscriptions automatically renew until you cancel.
          </span>
          <span>
            When making a purchase on the Service, you may have the option to
            pay through an External Service, such as with your Apple ID or
            Google Play account ("your External Service Account"), and your
            External Service Account will be charged for the purchase in
            accordance with the terms disclosed to you at the time of purchase
            and the general terms applicable to your External Service Account.
            Some External Services may charge you sales tax, depending on where
            you live, which may change from time to time.
          </span>
          <span>
            If your External Service Purchase includes an automatically renewing
            subscription, your External Service Account will continue to be
            periodically charged for the subscription until you cancel. After
            your initial subscription commitment period, and again after any
            subsequent subscription period, the subscription will automatically
            continue for the price and time period you agreed to when
            subscribing. If you received a discounted promotional offer, your
            price may go up per the terms of that offer following the initial
            subscription period for any subsequent renewal period.
          </span>
          <span>
            To cancel a subscription: If you do not want your subscription to
            renew automatically, or if you want to change or terminate your
            subscription, you must log in to your External Service Account and
            follow instructions to manage or cancel your subscription, even if
            you have otherwise deleted your account with us or if you have
            deleted the App from your device. For example, if you subscribed
            using your Apple ID, cancellation is handled by Apple, not Trinity.
            To cancel a purchase made with your Apple ID, go to Settings within
            the app , and follow the instructions to cancel. You can also
            request assistance at Apple Support.
          </span>
          <span>
            Similarly, if you subscribed on Google Play, cancellation is handled
            by Google. To cancel a purchase made through Google Play, launch the
            Google Play app on your mobile device and navigate to Settings. You
            can also request assistance at Google Play. If you cancel a
            subscription, you may continue to use the canceled service until the
            end of your then-current subscription term. The subscription will
            not be renewed when your then-current term expires. If you initiate
            a chargeback or otherwise reverse a payment made with your External
            Service Account, Trinity may terminate your account immediately in
            its sole discretion, on the basis that you have determined that you
            do not want a Trinity subscription. In the event that your
            chargeback or other payment reversal is overturned, please contact
            Customer Care. Trinity will retain all funds charged to your
            External Service Account until you cancel your subscription through
            your External Service Account. Certain users may be entitled to
            request a refund. See Section 8d below for more information.
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            8b. INTERNAL PURCHASES AND SUBSCRIPTIONS
          </Typography>
          <span>
            Internal Purchases, including subscriptions, are processed using the
            Payment Method you provide on the Website or App. Subscriptions
            automatically renew until you cancel. If you make an Internal
            Purchase, you agree to pay the prices displayed to you for the
            Services you've selected as well as any sales or similar taxes that
            may be imposed on your payments (and as may change from time to
            time), and you authorize Trinity to charge the payment method you
            provide (your "Payment Method"). Trinity may correct any billing
            errors or mistakes even if we have already requested or received
            payment. If you initiate a chargeback or otherwise reverse a payment
            made with your Payment Method, Trinity may terminate your account
            immediately in its sole discretion, on the basis that you have
            determined that you do not want a Trinity subscription. In the event
            that your chargeback or other payment reversal is overturned, please
            contact Customer Care.
          </span>
          <span>
            If your Internal Purchase includes an automatically renewing
            subscription, your Payment Method will continue to be periodically
            charged for the subscription until you cancel. After your initial
            subscription commitment period, and again after any subsequent
            subscription period, your subscription will automatically continue
            for the price and time period you agreed to when subscribing, until
            you cancel.
          </span>
          <span>
            To cancel a subscription, log in to the Website or App and go to the
            Account section. If you cancel a subscription, you may continue to
            use the cancelled service until the end of your then-current
            subscription term. The subscription will not be renewed when your
            then-current term expires.
          </span>
          <span>
            You may edit your Payment Method information by using the Settings
            tool and following the link to let your upgrade expire. If a payment
            is not successfully processed, due to expiration, insufficient
            funds, or otherwise, you remain responsible for any uncollected
            amounts and authorize us to continue billing the Payment Method, as
            it may be updated. This may result in a change to your payment
            billing dates.
          </span>
          <span>
            In addition, you authorize us to obtain updated or replacement
            expiration dates and card numbers for your credit or debit card as
            provided by your credit or debit card issuer. The terms of your
            payment will be based on your Payment Method and may be determined
            by agreements between you and the financial institution, credit card
            issuer, or other provider of your chosen Payment Method. If you
            reside outside of the Americas, you agree that your payment to
            Trinity may be through MTCH Technology Services Limited. Certain
            users may be entitled to request a refund. See Section 8d below for
            more information.
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            8c. VIRTUAL ITEMS
          </Typography>
          <span>
            Virtual items are non-refundable and subject to certain conditions.
          </span>
          <span>
            From time to time, you may have the opportunity to purchase a
            limited, personal, non-transferable, non-sublicensable, revocable
            license to use or access special limited-use features including but
            not limited to credits redeemable on virtual items, such as Super
            Like, Boost, Live Credits, or Gifts ("Virtual Item(s)") from
            Trinity. You may only purchase Virtual Items from us or our
            authorized partners through our Services.
          </span>
          <span>
            Virtual Items represent a limited license right governed by this
            Agreement, and, except as otherwise prohibited by applicable law, no
            title or ownership in or to Virtual Items is being transferred or
            assigned to you. This Agreement should not be construed as a sale of
            any rights in Virtual Items.
          </span>
          <span>
            Any Virtual Item balance shown in your account does not constitute a
            real-world balance or reflect any stored value, but instead
            constitutes a measurement of the extent of your license. Virtual
            Items do not incur fees for non-use; however, the license granted to
            you in Virtual Items will terminate in accordance with the terms of
            this Agreement, on the earlier of when Trinity ceases providing our
            Services, or your account is otherwise closed or terminated.
          </span>
          <span>
            Trinity, in its sole discretion, reserves the right to charge fees
            for the right to access or use Virtual Items and/or may distribute
            Virtual Items with or without charge. Trinity may manage, regulate,
            control, modify, or eliminate Virtual Items at any time, including
            taking actions that may impact the perceived value or purchase
            price, if applicable, of any Virtual Items. Trinity shall have no
            liability to you or any third party in the event that Trinity
            exercises any such rights. The transfer of Virtual Items is
            prohibited, and you shall not sell, redeem, or otherwise transfer
            Virtual Items to any person or entity. Virtual Items may only be
            redeemed through our Services.
          </span>
          <span>
            ALL PURCHASES AND REDEMPTIONS OF VIRTUAL ITEMS MADE THROUGH OUR
            SERVICES ARE FINAL AND NON-REFUNDABLE. YOU ACKNOWLEDGE THAT Trinity
            IS NOT REQUIRED TO PROVIDE A REFUND FOR ANY REASON, AND THAT YOU
            WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED VIRTUAL
            ITEMS WHEN AN ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY
            OR INVOLUNTARY.
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            8d. REFUNDS
          </Typography>
          <span>
            Generally, all purchases are nonrefundable. Special terms for
            refunds apply in the EU, EEA, UK, Switzerland, Korea, and Israel.
            Special terms for refunds also apply in Arizona, California,
            Colorado, Connecticut, Illinois, Iowa, Minnesota, New York, North
            Carolina, Ohio, Rhode Island, and Wisconsin.
          </span>
          <span>
            Generally, all purchases are final and nonrefundable, and there are
            no refunds or credits for partially used periods, except if the laws
            applicable in your jurisdiction provide for refunds. For subscribers
            residing in the EU, EEA, UK, and Switzerland: In accordance with
            local law, you are entitled to a full refund during the 14 days
            after the subscription begins. Please note that this 14-day period
            commences when the subscription starts.
          </span>
          <span>For subscribers residing in Germany:</span>
          <span>
            You may terminate your subscription after it has renewed with a
            notice period of one month.
          </span>
          <span>Your right to terminate for cause remains unaffected.</span>
          <span>
            For subscribers and purchasers of Virtual Items residing in the
            Republic of Korea: in accordance with local law, you are entitled to
            a full refund of your subscription and/or unused Virtual Items
            during the 7 days following the purchase. Please note that this
            7-day period commences upon the purchase.
          </span>
          <span>
            For subscribers residing in Arizona, California, Colorado,
            Connecticut, Illinois, Iowa, Minnesota, New York, North Carolina,
            Ohio, Rhode Island, Wisconsin, and Israel:
          </span>
          <span>
            Your Right to Cancel - You may cancel your subscription, without
            penalty or obligation, at any time prior to midnight of the third
            business day following the date you subscribed. In the event that
            you die before the end of your subscription period, your estate
            shall be entitled to a refund of that portion of any payment you had
            made for your subscription which is allocable to the period after
            your death. In the event that you become disabled (such that you are
            unable to use our Services) before the end of your subscription
            period, you shall be entitled to a refund of that portion of any
            payment you had made for your subscription which is allocable to the
            period after your disability by providing the Company notice in the
            same manner as you request a refund as described below.
          </span>
          <span>
            {
              'If any of the above apply to you and you subscribed using your Apple ID, your refund requests are handled by Apple, not Trinity. To request a refund, please contact your External Service directly; for example using your Apple device, go to Settings > iTunes & App Stores > [click on your Apple ID] > View Apple ID > Purchase History. Find the transaction and select "Report a Problem." You can also request a refund at Apple Support. For any other purchase, please contact Trinity at admin@learntrinity.com with your email by emailing or delivering a signed and dated notice which states that you, the buyer, are canceling this Agreement, or words of similar effect. Please also include the email address or telephone number associated with your account along with your order number.'
            }
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            9. ACCOUNT TERMINATION
          </Typography>
          <span>
            If you no longer wish to use our Services, or if we terminate your
            account for any reason, here's what you need to know.
          </span>
          <span>
            You can delete your account at any time by logging into the Website
            or App, going to "Settings", clicking "Delete Account", and
            following the instructions to complete the deletion process.
            However, you will need to cancel / manage any External Service
            Purchases through your External Service Account (e.g., iTunes,
            Google Play) to avoid additional billing.
          </span>
          <span>
            Trinity reserves the right to investigate and, if appropriate,
            suspend or terminate your account without a refund if Trinity
            believes that you have violated these Terms, misused our Services,
            or behaved in a way that Trinity regards as inappropriate or
            unlawful, on or off our Services. We reserve the right to make use
            of any personal, technological, legal, or other means available to
            enforce the Terms, at any time without liability and without the
            obligation to give you prior notice, including, but not limited to,
            preventing you from accessing the Services.
          </span>
          <span>
            If your account is terminated by you or by Trinity for any reason,
            these Terms continue and remain enforceable between you and Trinity,
            and you will not be entitled to any refund for purchases made. Your
            information will be maintained and deleted in accordance with our
            <></> <LinkPrivacy />
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            10. DISCLAIMER
          </Typography>
          <span>
            Trinity's Services are provided "as is" and we do not make, and
            cannot make, any representations about the content or features of
            our Services or Member Content. Trinity PROVIDES OUR SERVICES ON AN
            "AS IS" AND "AS AVAILABLE" BASIS AND TO THE EXTENT PERMITTED BY
            APPLICABLE LAW, GRANTS NO WARRANTIES OF ANY KIND, WHETHER EXPRESS,
            IMPLIED, STATUTORY OR OTHERWISE WITH RESPECT TO OUR SERVICES
            (INCLUDING ALL CONTENT CONTAINED THEREIN), INCLUDING, WITHOUT
            LIMITATION, ANY IMPLIED WARRANTIES OF SATISFACTORY QUALITY,
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
            NON-INFRINGEMENT. Trinity DOES NOT REPRESENT OR WARRANT THAT (A) OUR
            SERVICES WILL BE UNINTERRUPTED, SECURE, OR ERROR FREE, (B) ANY
            DEFECTS OR ERRORS IN OUR SERVICES WILL BE DISCOVERED OR CORRECTED,
            OR (C) THAT ANY CONTENT OR INFORMATION YOU OBTAIN ON OR THROUGH OUR
            SERVICES WILL BE ACCURATE, COMPLETE, CURRENT OR APPROPRIATE FOR YOUR
            PURPOSES. FURTHERMORE, Trinity MAKES NO GUARANTEES AS TO THE NUMBER
            OF ACTIVE USERS AT ANY TIME; USERS' ABILITY OR DESIRE TO COMMUNICATE
            WITH OR MEET YOU, OR THE ULTIMATE COMPATIBILITY WITH OR CONDUCT BY
            USERS YOU MEET THROUGH THE SERVICES. Trinity ASSUMES NO
            RESPONSIBILITY FOR ANY CONTENT THAT YOU OR ANOTHER USER OR THIRD
            PARTY POSTS, SENDS, RECEIVES, OR ACTS ON THROUGH OUR SERVICES, NOR
            DOES Trinity ASSUME ANY RESPONSIBILITY FOR THE IDENTITY, INTENTIONS,
            LEGITIMACY, OR VERACITY OF ANY USERS WITH WHOM YOU MAY COMMUNICATE
            WITH THROUGH Trinity. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED
            THROUGH THE USE OF OUR SERVICES IS ACCESSED AT YOUR OWN DISCRETION
            AND RISK. Trinity IS NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER
            HARDWARE, COMPUTER SOFTWARE, OR OTHER EQUIPMENT OR TECHNOLOGY
            INCLUDING, BUT WITHOUT LIMITATION, DAMAGE FROM ANY SECURITY BREACH
            OR FROM ANY VIRUS, BUGS, TAMPERING, HACKING, FRAUD, ERROR, OMISSION,
            INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER
            LINE OR NETWORK FAILURE, OR ANY OTHER TECHNICAL OR OTHER DISRUPTION
            OR MALFUNCTION.
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            11. DIGITAL MILLENNIUM COPYRIGHT ACT
          </Typography>
          <span>
            We take copyright infringement very seriously. We ask you to help us
            to ensure we address it promptly and effectively.
          </span>
          <span>
            Trinity has adopted the following policy towards copyright
            infringement in accordance with the Digital Millennium Copyright Act
            (the "DMCA"). If you believe any Member Content or Our Content
            infringes upon your intellectual property rights, please submit a
            notification alleging such infringement ("DMCA Takedown Notice")
            including the following:
          </span>
          <span>
            1. A physical or electronic signature of a person authorized to act
            on behalf of the owner of an exclusive right that is allegedly
            infringed;
          </span>
          <span>
            2. Identification of the copyrighted work claimed to have been
            infringed, or, if multiple copyrighted works at a single online site
            are covered by a single notification, a representative list of such
            works;
          </span>
          <span>
            3. Identification of the material claimed to be infringing or to be
            the subject of infringing activity and that is to be removed or
            access disabled and information reasonably sufficient to permit the
            service provider to locate the material;
          </span>
          <span>
            4. Information reasonably sufficient to permit the service provider
            to contact you, such as an address, telephone number, and, if
            available, an electronic mail;
          </span>
          <span>
            5. A statement that you have a good faith belief that use of the
            material in the manner complained of is not authorized by the
            copyright owner, its agent, or the law;
          </span>
          <span>
            6. A statement that, under penalty of perjury, the information in
            the notification is accurate and you are authorized to act on behalf
            of the owner of the exclusive right that is allegedly infringed.
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            12. ADS AND THIRD-PARTY CONTENT
          </Typography>
          <span>
            You may see ads and third-party promotions on our Services. Trinity
            does not endorse and isn’t responsible for your interactions with
            those products or services.
          </span>
          <span>
            Our Services may contain advertisements and promotions offered by
            third parties and links to other websites or resources. In addition,
            our Services may allow you to watch an advertisement in exchange for
            Virtual Items; Trinity does not guarantee that you will always be
            eligible to watch such advertisements, or that any such
            advertisements will be available. Trinity may also provide
            non-commercial links or references to third parties within its
            content. Trinity is not responsible for the availability (or lack of
            availability) of any external websites or resources or their
            content. Furthermore, Trinity is not responsible for, and does not
            endorse, any products or services that may be offered by third-party
            websites or resources. If you choose to interact with the third
            parties made available through our Services, such party's terms will
            govern their relationship with you. Trinity is not responsible or
            liable for such third parties' terms or actions.
          </span>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            13. LIMITATION OF LIABILITY
          </Typography>
          <span>
            Trinity's liability is limited to the maximum extent allowed by
            applicable law.
          </span>
          <span>
            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
            Trinity, ITS AFFILIATES, EMPLOYEES, LICENSORS, OR SERVICE PROVIDERS
            BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
            SPECIAL, PUNITIVE, FIXED, OR ENHANCED DAMAGES, INCLUDING, WITHOUT
            LIMITATION, LOSS OF PROFITS, WHETHER INCURRED DIRECTLY OR
            INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE
            LOSSES, RESULTING FROM: (I) YOUR ACCESS TO OR USE OF OR INABILITY TO
            ACCESS OR USE THE SERVICES, (II) THE CONDUCT OR CONTENT OF ANY USERS
            OR THIRD PARTIES ON OR THROUGH ANY OF OUR AFFILIATES’ SERVICES OR IN
            CONNECTION WITH THE SERVICES; OR (III) ANY UNAUTHORIZED ACCESS, USE,
            OR ALTERATION OF YOUR CONTENT, EVEN IF Trinity HAS BEEN ADVISED OF
            THE POSSIBILITY OF SUCH DAMAGES. TO THE FULLEST EXTENT PERMITTED BY
            APPLICABLE LAW, IN NO EVENT WILL Trinity'S AGGREGATE LIABILITY TO
            YOU FOR ALL CLAIMS RELATING TO THE SERVICES EXCEED THE GREATER OF
            (A) $100 USD OR (B) THE AMOUNT PAID, IF ANY, BY YOU TO Trinity FOR
            THE SERVICES DURING THE TWENTY-FOUR (24) MONTH PERIOD IMMEDIATELY
            PRECEDING THE DATE THAT YOU FIRST FILE A LAWSUIT, ARBITRATION OR ANY
            OTHER LEGAL PROCEEDING AGAINST Trinity, WHETHER STATUTORY, IN LAW OR
            IN EQUITY, IN ANY TRIBUNAL. THE DAMAGES LIMITATION SET FORTH IN THE
            IMMEDIATELY PRECEDING SENTENCE APPLIES (i) REGARDLESS OF THE GROUND
            UPON WHICH LIABILITY IS BASED (WHETHER DEFAULT, CONTRACT, TORT,
            STATUTE, OR OTHERWISE), (ii) IRRESPECTIVE OF THE TYPE OF BREACH OF
            RIGHTS, PRIVILEGES, OR OBLIGATIONS, AND (iii) WITH RESPECT TO ALL
            EVENTS, THE SERVICE, AND THIS AGREEMENT.
          </span>
          <span>
            THE LIMITATION OF LIABILITY PROVISIONS SET FORTH IN THIS SECTION 14
            SHALL APPLY EVEN IF YOUR REMEDIES UNDER THIS AGREEMENT FAIL WITH
            RESPECT TO THEIR ESSENTIAL PURPOSE.
          </span>
          <span>
            SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF CERTAIN DAMAGES,
            SO SOME OR ALL OF THE LIMITATIONS IN THIS SECTION MAY NOT APPLY TO
            YOU.
          </span>
        </Stack>
      </Container>
    </LandingPageStyled>
  );
};

export default Term;
