import { Button } from "@mui/material";

interface HeaderButtonProp {
  title: string;
  onClick: () => void;
}

const HeaderButton: React.FC<HeaderButtonProp> = ({ title, onClick }) => {
  return (
    <Button
      variant="text"
      onClick={onClick}
      sx={{
        fontFamily: "Chirp",
        fontSize: {
          md: "18px",
          xs: "16px",
        },
        fontWeight: "500",
        lineHeight: {
          xs: "19.09px",
          md: "21.47px",
        },
        textAlign: "center",
        textTransform: "none",
        color: "white",
      }}
    >
      {title}
    </Button>
  );
};

export default HeaderButton;
