import { Box, Stack, Typography } from "@mui/material";
import AppIcon from "../logo/AppIcon";
import { ImageStyle } from "../body/Features";
import BottomImage from "../assets/Gradient.png";
import Domain from "../assets/Domain.svg";
import Phone from "../assets/Phone.svg";
import AddressIcon from "../assets/Address.svg";

const Address: React.FC = () => {
  return (
    <Stack
      spacing={2}
      sx={{
        alignItems: "center",
        position: "relative",
      }}
    >
      <AppIcon />
      <Typography
        sx={{
          fontSize: {
            md: "18px",
            xs: "18px",
          },
          fontWeight: "normal",
          color: "white",
        }}
      >
        Tomorrow Education Limited
      </Typography>
      <Typography
        sx={{
          fontSize: {
            md: "15px",
            xs: "15px",
          },
          fontWeight: 700,
          color: "white",
        }}
      >
        TAX CODE: 0314360571
      </Typography>
      <Box height="40px"></Box>
      <Stack
        direction={{ md: "row", xs: "column" }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
        spacing={2}
      >
        <Stack direction="row" spacing={1}>
          <ImageStyle src={Domain} width={20} height={20} />
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "normal",
              color: "white",
              lineHeight: "21.47px",
            }}
          >
            admin@learntrity.com
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <ImageStyle src={AddressIcon} width={20} height={20} />
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "normal",
              color: "white",
              lineHeight: "21.47px",
            }}
          >
            447G Le Van Viet, Thu Duc City, Ho Chi Minh City, Vietnam
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <ImageStyle src={Phone} width={20} height={20} />
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "normal",
              color: "white",
              lineHeight: "21.47px",
            }}
          >
            02866856428
          </Typography>
        </Stack>
      </Stack>
      <Box height="40px"></Box>
      {/* <ImageStyle
        src={BottomImage}
        sx={{
          width: "100%",
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      /> */}
    </Stack>
  );
};

export default Address;
