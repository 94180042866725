import { Box, Stack, styled } from "@mui/material";
import Introduction from "./Introduction";
import Features from "./Features";
import OurTeam from "../our_team/OurTeam";
import Footer from "../footer/Footer";
import Address from "../address/Address";

const ContentStyled = styled(Stack)({
  padding: "120px 0px 0px 0px",
});

const Content: React.FC = () => {
  return (
    <ContentStyled spacing={12}>
      <Introduction />
      <Features />
      <OurTeam />
      <Box />
      <Footer />
      <Address />
    </ContentStyled>
  );
};

export default Content;
