import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import LadingPage from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Term from "./privacy/term";
import Privacy from "./privacy/privacy";
import AppDirector from "./app_director/app_director";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <LadingPage />,
    errorElement: <LadingPage />,
  },
  {
    path: "/term",
    element: <Term />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/app/*",
    element: <AppDirector/>
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
