import { Container, Stack, Typography } from "@mui/material";
import { LandingPageStyled } from "../App";
import LadingPage from "../App";


const AppDirector: React.FC = () => {
    let os = getMobileOperatingSystem().toLowerCase();    

    if (os === "android") {
        window.location.href = "https://play.google.com/store/apps/details?id=en.app.trinity";

    } else if (os === "ios") {
        window.location.href = "https://apps.apple.com/vn/app/trinity/id6657977300";
    }


  return <LadingPage/>;
};

function getMobileOperatingSystem() {
    var platform = navigator.platform;

    var isIOS = /iPad|iPhone|iPod|Mac/.test(platform);

    if (isIOS) {
        return 'iOS';
    }

    return 'Android';

}

export default AppDirector;
