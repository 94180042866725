import { Box, Stack, styled, Typography } from "@mui/material";
import InstallButton from "../header/InstallButton";
const ImageStyle = styled(`img`)({});

const Footer: React.FC = () => {
  return (
    <Stack
      sx={{
        alignItems: "center",
      }}
      spacing={{
        md: 8,
        xs: 4,
      }}
    >
      <Typography
        sx={{
          fontFamily: "Chirp",
          fontSize: {
            md: "48px",
            xs: "24px",
          },
          lineHeight: {
            md: "57.26px",
            xs: "28.63px",
          },
          fontWeight: 500,
          textAlign: "center",
        }}
      >
        Ready to get Trinity?
      </Typography>
      <Box>
        <InstallButton />
      </Box>
    </Stack>
  );
};

export default Footer;
