import { Container, Stack, Typography } from "@mui/material";
import { LandingPageStyled } from "../App";

const Privacy: React.FC = () => {
  return (
    <LandingPageStyled>
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: "40px",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingBottom: "40px",
        }}
      >
        <Stack spacing={1}>
          <Typography
            sx={{
              fontSize: 32,
              fontFamily: "Chirp",
              textAlign: "center",
            }}
          >
            Privacy Policy
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: 12,
                md: 16,
              },
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            1. Introduction
          </Typography>
          <ul>
            <li>
              This Privacy Policy explains how Trinity collects, uses, and
              shares your personal information when you use our app.
            </li>
          </ul>
          <Typography
            sx={{
              fontSize: {
                xs: 12,
                md: 16,
              },
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            2. Information We Collect
          </Typography>
          <ul>
            <li>
              We collect information you provide when you create an account,
              such as your name, email address, and profile information. We also
              collect data automatically, such as your IP address, device
              information, and usage data.
            </li>
          </ul>
          <Typography
            sx={{
              fontSize: {
                xs: 12,
                md: 16,
              },
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            3. How We Use Your Information
          </Typography>
          <ul>
            <li>
              We use your information to provide and improve our services,
              personalize your experience, and communicate with you. We may also
              use your information for research and analytics purposes.
            </li>
          </ul>
          <Typography
            sx={{
              fontSize: {
                xs: 12,
                md: 16,
              },
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            4. Sharing Your Information
          </Typography>
          <ul>
            <li>
              We do not sell your personal information. We may share your
              information with third-party service providers who help us operate
              the app. We may also share information if required by law or to
              protect our rights.
            </li>
          </ul>
          <Typography
            sx={{
              fontSize: {
                xs: 12,
                md: 16,
              },
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            5. Data Retention
          </Typography>
          <ul>
            <li>
              We retain your information for as long as necessary to provide our
              services and comply with legal obligations. You may request the
              deletion of your account and associated data at any time.
            </li>
          </ul>
          <Typography
            sx={{
              fontSize: {
                xs: 12,
                md: 16,
              },
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            6. Security
          </Typography>
          <ul>
            <li>
              We take reasonable measures to protect your personal information.
              However, no security system is foolproof, and we cannot guarantee
              the security of your data.
            </li>
          </ul>
          <Typography
            sx={{
              fontSize: {
                xs: 12,
                md: 16,
              },
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            7. Children's Privacy
          </Typography>
          <ul>
            <li>
              Trinity is not intended for children under 13. We do not knowingly
              collect personal information from children under 13. If we become
              aware that we have collected such information, we will take steps
              to delete it.
            </li>
          </ul>
          <Typography
            sx={{
              fontSize: {
                xs: 12,
                md: 16,
              },
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            8. Your Rights
          </Typography>
          <ul>
            <li>
              You have the right to access, correct, or delete your personal
              information. You may also object to or restrict certain processing
              of your data.
            </li>
          </ul>
          <Typography
            sx={{
              fontSize: {
                xs: 12,
                md: 16,
              },
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            9. International Data Transfers
          </Typography>
          <ul>
            <li>
              Your information may be transferred to and processed in countries
              outside of your own. We take steps to ensure that your data is
              protected in accordance with this Privacy Policy.
            </li>
          </ul>
          <Typography
            sx={{
              fontSize: {
                xs: 12,
                md: 16,
              },
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            10. Changes to the Privacy Policy
          </Typography>
          <ul>
            <li>
              We may update this Privacy Policy from time to time. You will be
              notified of any changes, and your continued use of the app after
              such changes will constitute acceptance of the new Privacy Policy.
            </li>
          </ul>
          <Typography
            sx={{
              fontSize: {
                xs: 12,
                md: 16,
              },
              fontFamily: "Chirp",
              textAlign: "start",
              fontWeight: 700,
            }}
          >
            11. Contact Information
          </Typography>
          <ul>
            <li>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at admin@learntrinity.com.
            </li>
          </ul>
        </Stack>
      </Container>
    </LandingPageStyled>
  );
};

export default Privacy;
