import { Box, Stack, Typography } from "@mui/material";
import { ImageStyle, Title } from "../body/Features";
import PhuImage from "../assets/Phu.jpg";
import QuocImage from "../assets/Quoc.jpg";
import TanImage from "../assets/Tan.jpg";
import DuyImage from "../assets/Duy.jpg";

interface OurTeamCardProp {
  image: any;
  name: string;
  title: string;
  isMobile: boolean;
}

const OurTeamCard: React.FC<OurTeamCardProp> = ({
  image,
  name,
  title,
  isMobile,
}) => {
  return (
    <Box
      sx={{
        background: "rgba(97, 173, 120, 0.1)",
        borderRadius: "12px",
        padding: "12px 12px 16px 12px",
      }}
    >
      <Stack direction="column" spacing={2}>
        <ImageStyle
          src={image}
          width={isMobile ? 160 : 220}
          height={isMobile ? 180 : 260}
        />
        <Typography
          sx={{
            fontWeight: "500",
            lineHeight: { md: "26.25px", xs: "21.47px" },
            color: "white",
            fontSize: { md: "22px", xs: "18px" },
          }}
        >
          {name}
        </Typography>
        <Typography
          sx={{
            fontSize: { md: "16px", xs: "14px" },
            fontWeight: "400",
            lineHeight: { md: "19.09px", xs: "16.7px" },
            color: "#B0B0B0",
            transform: "none",
          }}
        >
          {title}
        </Typography>
      </Stack>
    </Box>
  );
};

const OurTeam: React.FC = () => {
  return (
    <Stack
      sx={{
        alignItems: "center",
      }}
      spacing={{
        md: 12,
        xs: 10,
      }}
      className="ourteam-section"
    >
      <Title title="Our team" />
      <Box
        sx={{
          display: { md: "block", xs: "none" },
        }}
      >
        <Stack direction="row" spacing={2}>
          <OurTeamCard
            image={QuocImage}
            name="Lê Hữu Quốc"
            title="Founder/ CEO"
            isMobile={false}
          />
          <OurTeamCard
            image={TanImage}
            name="Hoàng Minh Tân"
            title="Full Stack Engineer"
            isMobile={false}
          />
          <OurTeamCard
            image={PhuImage}
            name="Nguyễn Duy Phú"
            title="Back End Engineer"
            isMobile={false}
          />
          <OurTeamCard
            image={DuyImage}
            name="Trương Nhật Duy"
            title="Mobile Engineer"
            isMobile={false}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          display: { md: "none", xs: "block" },
        }}
      >
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2}>
            <OurTeamCard
              image={QuocImage}
              name="Lê Hữu Quốc"
              title="Founder/ CEO"
              isMobile={true}
            />
            <OurTeamCard
              image={TanImage}
              name="Hoàng Minh Tân"
              title="Full Stack Engineer"
              isMobile={true}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <OurTeamCard
              image={PhuImage}
              name="Nguyễn Duy Phú"
              title="Back End Engineer"
              isMobile={true}
            />
            <OurTeamCard
              image={DuyImage}
              name="Trương Nhật Duy"
              title="Mobile Engineer"
              isMobile={true}
            />
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default OurTeam;
