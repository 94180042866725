import styled from "@emotion/styled";
import logo from "../assets/logo.png";
import { Typography } from "@mui/material";

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledImage = styled.img`
  width: 48px;
  height: 48px;
`;

const AppIcon: React.FC = () => {
  return (
    <ImageContainer>
      <StyledImage src={logo} />
      <Typography
        sx={{
          fontFamily: "Rowdies",
          fontSize: {
            md: "32px",
            xs: "24px",
          },
          fontWeight: 300,
          lineHeight: {
            xs: "29.81px",
            md: "39.74px",
          },
        }}
      >
        TRINITY
      </Typography>
    </ImageContainer>
  );
};

export default AppIcon;
