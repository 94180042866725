import { Box, Container, Stack, styled, Typography } from "@mui/material";
import TranslatedImage from "../assets/translated.png";
import BilingualImage from "../assets/Bilingual.png";
import TtsImage from "../assets/Tts.png";

export const ImageStyle = styled(`img`)({});

interface FeatureTitle {
  title: string;
}

const TranslatedLarge: React.FC = () => {
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
      }}
    >
      <ImageStyle
        src={TranslatedImage}
        sx={{
          width: "50%",
        }}
      />
      <Stack spacing={3}>
        <Typography
          sx={{
            fontFamily: "Chirp",
            fontSize: "48px",
            fontWeight: 500,
            lineHeight: "57.26px",
            letterSpacing: "3px",
          }}
        >
          Translate
        </Typography>
        <Typography
          sx={{
            fontFamily: "Chirp",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "21.47px",
            color: "#B0B0B0",
          }}
        >
          Just click to translate words you don’t understand
        </Typography>
      </Stack>
    </Stack>
  );
};

const TranslatedSmall: React.FC = () => {
  return (
    <Stack
      sx={{
        alignItems: "center",
      }}
    >
      <Stack
        spacing={3}
        sx={{
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Chirp",
            fontSize: "26px",
            fontWeight: 500,
            lineHeight: "31.02px",
            letterSpacing: "1px",
          }}
        >
          Translate
        </Typography>
        <Typography
          sx={{
            fontFamily: "Chirp",
            fontSize: {
              md: "18px",
              xs: "15px",
            },
            fontWeight: 400,
            lineHeight: {
              md: "21.47px",
              xs: "17.89px",
            },
            color: "#B0B0B0",
            width: "90%",
            textAlign: "center",
          }}
        >
          Just click to translate words you don’t understand
        </Typography>
      </Stack>
      <ImageStyle
        src={TranslatedImage}
        sx={{
          width: "100%",
        }}
      />
    </Stack>
  );
};

export const Title: React.FC<FeatureTitle> = ({ title }) => {
  return (
    <Typography
      sx={{
        margin: "40px 0px 40px 0px",
        fontFamily: "Palanquin",
        fontSize: {
          md: "64px",
          xs: "40px",
        },
        fontWeight: 700,
        lineHeight: {
          md: "115.9px",
          xs: "72.44px",
        },
        letterSpacing: {
          md: "3px",
          xs: "2px",
        },
      }}
    >
      {title}
    </Typography>
  );
};

const FeatureTranslated: React.FC = () => {
  return (
    <Stack>
      <Box
        display={{
          md: "block",
          xs: "none",
        }}
      >
        <TranslatedLarge />
      </Box>
      <Box
        display={{
          md: "none",
          xs: "block",
        }}
      >
        <TranslatedSmall />
      </Box>
    </Stack>
  );
};

const BilingualLarge: React.FC = () => {
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
      }}
    >
      <Stack spacing={3}>
        <Typography
          sx={{
            fontFamily: "Chirp",
            fontSize: "48px",
            fontWeight: 500,
            lineHeight: "57.26px",
            letterSpacing: "3px",
          }}
        >
          Bilingual
        </Typography>
        <Typography
          sx={{
            fontFamily: "Chirp",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "21.47px",
            color: "#B0B0B0",
          }}
        >
          Reading posts bilingual helps you improve your English to next level
        </Typography>
      </Stack>
      <ImageStyle
        src={BilingualImage}
        sx={{
          width: "50%",
        }}
      />
    </Stack>
  );
};

const BilingualSmall: React.FC = () => {
  return (
    <Stack
      sx={{
        alignItems: "center",
      }}
    >
      <Stack
        spacing={3}
        sx={{
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Chirp",
            fontSize: "26px",
            fontWeight: 500,
            lineHeight: "31.02px",
            letterSpacing: "1px",
          }}
        >
          Bilingual
        </Typography>
        <Typography
          sx={{
            fontFamily: "Chirp",
            fontSize: {
              md: "18px",
              xs: "15px",
            },
            fontWeight: 400,
            lineHeight: {
              md: "21.47px",
              xs: "17.89px",
            },
            color: "#B0B0B0",
            width: "90%",
            textAlign: "center",
          }}
        >
          Reading posts bilingual helps you improve your English to next level
        </Typography>
      </Stack>
      <ImageStyle
        src={BilingualImage}
        sx={{
          width: "100%",
        }}
      />
    </Stack>
  );
};

const FeatureBilingual: React.FC = () => {
  return (
    <Stack>
      <Box
        display={{
          md: "block",
          xs: "none",
        }}
      >
        <BilingualLarge />
      </Box>
      <Box
        display={{
          md: "none",
          xs: "block",
        }}
      >
        <BilingualSmall />
      </Box>
    </Stack>
  );
};

const TtsLarge: React.FC = () => {
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
      }}
    >
      <ImageStyle
        src={TtsImage}
        sx={{
          width: "50%",
        }}
      />
      <Stack spacing={3}>
        <Typography
          sx={{
            fontFamily: "Chirp",
            fontSize: "48px",
            fontWeight: 500,
            lineHeight: "57.26px",
            letterSpacing: "3px",
          }}
        >
          Text-to-Speech
        </Typography>
        <Typography
          sx={{
            fontFamily: "Chirp",
            fontSize: {
              md: "18px",
              xs: "15px",
            },
            fontWeight: 400,
            lineHeight: {
              md: "21.47px",
              xs: "17.89px",
            },
            color: "#B0B0B0",
            width: "90%",
            textAlign: "center",
          }}
        >
          Improve your reading and listening with our state-of-the-art text to
          speech technology
        </Typography>
      </Stack>
    </Stack>
  );
};

const TtsSmall: React.FC = () => {
  return (
    <Stack
      sx={{
        alignItems: "center",
      }}
    >
      <Stack
        spacing={3}
        sx={{
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Chirp",
            fontSize: "26px",
            fontWeight: 500,
            lineHeight: "31.02px",
            letterSpacing: "1px",
          }}
        >
          Text-to-Speech
        </Typography>
        <Typography
          sx={{
            fontFamily: "Chirp",
            fontSize: {
              md: "18px",
              xs: "15px",
            },
            fontWeight: 400,
            lineHeight: {
              md: "21.47px",
              xs: "17.89px",
            },
            color: "#B0B0B0",
            width: "90%",
            textAlign: "center",
          }}
        >
          Improve your reading and listening with our state-of-the-art text to
          speech technology
        </Typography>
      </Stack>
      <ImageStyle
        src={TtsImage}
        sx={{
          width: "100%",
        }}
      />
    </Stack>
  );
};

const FeatureTts: React.FC = () => {
  return (
    <Stack>
      <Box
        display={{
          md: "block",
          xs: "none",
        }}
      >
        <TtsLarge />
      </Box>
      <Box
        display={{
          md: "none",
          xs: "block",
        }}
      >
        <TtsSmall />
      </Box>
    </Stack>
  );
};

const Features: React.FC = () => {
  return (
    <Stack
      sx={{
        alignItems: "center",
      }}
      spacing={{
        md: 12,
        xs: 10,
      }}
      className="features-section"
    >
      <Title title="Features" />
      <FeatureTranslated />
      <FeatureBilingual />
      <FeatureTts />
    </Stack>
  );
};

export default Features;
