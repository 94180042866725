import AppIcon from "../logo/AppIcon";
import styled from "@emotion/styled";
import HeaderButton from "./HeaderButton";
import InstallButton from "./InstallButton";
import { Box, Container, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Headerstyled = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  color: "#fff",
  zIndex: 1000,
  backgroundColor: "#052711",
  height: "80px",
  paddingTop: "20px",
});

const ContainerStyled = styled(Container)({
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const Header: React.FC = () => {
  const navigator = useNavigate();
  return (
    <Headerstyled>
      <ContainerStyled>
        <AppIcon />
        <Stack
          direction="row"
          spacing={{
            md: 4,
            xs: 2,
          }}
        >
          <HeaderButton
            title="Home"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          />
          <HeaderButton
            title="Features"
            onClick={() => {
              const section = document.querySelector(".features-section");
              if (section) {
                window.scrollTo({
                  top:
                    section.getBoundingClientRect().top + window.scrollY - 100,
                  behavior: "smooth",
                });
              }
            }}
          />
          <HeaderButton
            title="Terms of service"
            onClick={() => {
              navigator("/term");
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          />
          <HeaderButton
            title="Privacy"
            onClick={() => {
              window.location.href = "https://learntrinity.com/privacy/";
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          />
        </Stack>
        <Box display={{ xs: "none", md: "block" }}>
          <InstallButton />
        </Box>
      </ContainerStyled>
    </Headerstyled>
  );
};

export default Header;
