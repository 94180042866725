import { Box, Container, Stack, styled, Typography } from "@mui/material";
import InstallButton from "../header/InstallButton";
import IntroductionImage from "../assets/introduction.png";

const IntroductionImageStyle = styled(`img`)({});

const Introduction: React.FC = () => {
  return (
    <Container
      sx={{
        boxShadow: "0px 4px 48px 0px #0000001A",
        background: {
          md: "#FFFFFF0F",
          xs: "none",
        },
        borderRadius: "80px",
        padding: "20px 20px 20px 20px",
      }}
      className="introduction-section"
    >
      <Stack
        direction={{ md: "row", xs: "column" }}
        sx={{
          position: "relative",
        }}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Stack
          spacing={3}
          sx={{
            position: {
              md: "absolute",
            },
            left: 40,
            maxWidth: {
              lg: "52%",
              md: "50%",
              xs: "100%",
            },
            alignItems: {
              xs: "center",
              md: "self-start",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Palanquin",
              fontWeight: 700,
              fontSize: {
                lg: "80px",
                md: "60px",
                xs: "40px",
              },
              lineHeight: {
                lg: "89px",
                md: "70px",
                xs: "50px",
              },
              letterSpacing: {
                md: "3px",
                xs: "2px",
              },
              textAlign: {
                md: "start",
                xs: "center",
              },
            }}
          >
            The Social Network for English Learners
          </Typography>
          <Box>
            <InstallButton />
          </Box>
          <Stack direction="row" spacing={6}>
            <Stack>
              <Typography
                sx={{
                  fontFamily: "Chirp",
                  fontSize: {
                    md: "40px",
                    xs: "26px",
                  },
                  fontWeight: 700,
                  lineHeight: {
                    md: "47.72px",
                    xs: "31.02px",
                  },
                }}
              >
                4.8
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Chirp",
                  fontSize: {
                    md: "20px",
                    xs: "14px",
                  },
                  fontWeight: 500,
                  lineHeight: {
                    md: "23.86px",
                    xs: "16.7px",
                  },
                }}
              >
                Rating on AppStore
              </Typography>
            </Stack>
            <Stack>
              <Typography
                sx={{
                  fontFamily: "Chirp",
                  fontSize: {
                    md: "40px",
                    xs: "26px",
                  },
                  fontWeight: 700,
                  lineHeight: {
                    md: "47.72px",
                    xs: "31.02px",
                  },
                }}
              >
                10k+
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Chirp",
                  fontSize: {
                    md: "20px",
                    xs: "14px",
                  },
                  fontWeight: 500,
                  lineHeight: {
                    md: "23.86px",
                    xs: "16.7px",
                  },
                }}
              >
                Active users
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <IntroductionImageStyle
          src={IntroductionImage}
          sx={{
            width: {
              ld: "60%",
              md: "50%",
              xs: "100%",
            },
          }}
        />
      </Stack>
    </Container>
  );
};

export default Introduction;
